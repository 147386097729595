import React from "react";
import { default as theme } from './theme';
import Settings from "../settings.yml";

export const organization = {
  name: Settings.org_name,
  telephone: Settings.org_phone,
  email: Settings.org_email,
  colorPrimary: Settings.colorPrimary,
  colorSecondary: Settings.colorSecondary,
  colorTertiary: theme.palette.tertiary.main,
};

export const BarLogo = props => (
  <img
    style={{
      maxHeight: '5vh', margin: '.25vh 0 0 2.5vw'
    }}
    src={"/images/logoBar.png"}
    alt={`${organization.name} logo`}/>
);

export const LoginLogo = props => {
  return (
    <img style={{
        maxWidth: "150px",
        marginBottom: "4vh",
      }}
      src={"/images/logo.png"}
      alt={`${organization.name} logo`}
    />
  );
}

export { store, configureStore } from './store';
export { default as i18n } from './i18n';
export { default as theme } from './theme';
export { default as history } from './history';